<template>
  <div>
    <FloatWhatsapp v-if="$store.getters.getUserData" />
    <Chat
      v-if="$store.getters.getUserData && recentOrderData"
      :qbRegister="qbRegister"
      :order="recentOrderData.sellers[0].id"
      :merchant="recentOrderData.sellers[0].merchant_id"
      ref="chat"
      @setDefaultQbRegister="qbRegister = undefined"
    />
    <section class="product-list-heading mb-0">
      <div class="container">
        <!-- Breadcrumb -->
        <div class="breadcrumb">
          <router-link to="/">Home</router-link>
          <i class="icon-next"></i>
          <router-link to="/profile">My Account</router-link>
          <i class="icon-next"></i>
          <router-link to="/recent-order">Recent Order</router-link>
          <i class="icon-next"></i>
          <span>Order Detail</span>
        </div>
        <!-- New -->
        <template v-if="recentOrderData">
          <h2 class="mt-4">Order Detail</h2>
          <!-- Order Detail Info -->
          <div class="row justify-content-end border-topbottom">
            <div class="col-lg col-6">
              <p class="mb-1">Order Number</p>
              <p class="mt-1 mb-lg-0 bold">
                {{
                  !perMerchant
                    ? recentOrderData.order_number
                    : recentOrderData.sellers[0].order_number
                }}
              </p>
            </div>
            <div class="col-lg col-6">
              <p class="mb-1">Purchase Date</p>
              <p class="mt-1 mb-lg-0 bold">
                {{
                  formatDate(recentOrderData.order_date_string) +
                  " | " +
                  recentOrderData.order_date.split("T")[1].substring(0, 5)
                }}
              </p>
            </div>
            <div class="col-lg col-6">
              <p class="mb-1">Status</p>
              <p class="mt-1 mb-0 bold" :style="{'color' : getOrderStatusColor(recentOrderData)}">
                {{ getOrderStatusText(recentOrderData) }}
              </p>
            </div>
            <div class="col-lg col-6">
              <p class="mb-1">
                <template v-if="recentOrderData.sellers[0].tracking_number">
                  No Resi
                </template>
                <template v-if="recentOrderData.sellers[0].pickup_code">
                  Pickup Code
                </template>
              </p>
              <p class="mt-1 mb-0 text-gold bold">
                <template v-if="recentOrderData.sellers[0].tracking_number">
                  {{ recentOrderData.sellers[0].tracking_number }}
                  <img
                    class="cursor-pointer"
                    height="20"
                    src="@/assets/img/copy.png"
                    alt=""
                    @click="
                      copy(
                        recentOrderData.sellers[0].tracking_number,
                        'tracking_number'
                      )
                    "
                  />
                </template>
                <template v-if="recentOrderData.sellers[0].pickup_code">
                  {{ recentOrderData.sellers[0].pickup_code }}
                  <img
                    alt=""
                    class="cursor-pointer"
                    height="20"
                    src="@/assets/img/copy.png"
                    @click="
                      copy(
                        recentOrderData.sellers[0].pickup_code,
                        'pickup_code'
                      )
                    "
                  />
                </template>
              </p>
            </div>
            <div class="col-lg-4 col-12 text-right mt-lg-0 mt-2">
              <!-- Track Order Button -->
              <template v-if="recentOrderData.sellers[0].tracking_number">
                <!-- Order trackable with RajaOngkir -->
                <button
                  class="btn btn-wz-proceed"
                  v-b-modal="'modal-track-order'"
                  @click="selectedOrder = recentOrderData"
                  v-if="recentOrderData.sellers[0].track_order"
                >
                  TRACK ORDER
                </button>
                <!-- Order trackable with external url -->
                <a
                  :href="
                    recentOrderData.sellers[0].track_order_url +
                    recentOrderData.sellers[0].tracking_number
                  "
                  v-else-if="
                    !recentOrderData.sellers[0].track_order &&
                    recentOrderData.sellers[0].track_order_url
                  "
                >
                  <button class="btn btn-wz-proceed">TRACK ORDER</button>
                </a>
                <!-- Order untrackable -->
                <button
                  class="btn btn-wz-proceed"
                  v-else-if="
                    !recentOrderData.sellers[0].track_order &&
                    !recentOrderData.sellers[0].track_order_url
                  "
                  disabled
                >
                  UNTRACABLE
                </button>
              </template>
              <!-- Cancel button for unfinished / unpaid status -->
              <template v-if="/CH|WC/.test(recentOrderData.status)">
                <button
                  class="btn btn-wz-cancel"
                  v-b-modal="'modal-order-cancel'"
                  @click="selectedOrder = recentOrderData"
                >
                  Cancel Order
                </button>
                <router-link
                  class="btn btn-wz-proceed mt-2 mt-lg-0 ml-lg-2"
                  :to="`/order-completed/${recentOrderData.order_number}`"
                  v-if="!recentOrderData.payment_api_integration"
                >
                  Pay Now
                </router-link>
                <template
                  v-else-if="
                    !/gopay|shopeepay|qris/i.test(
                      recentOrderData.payment_method
                    )
                  "
                >
                  <a
                    v-if="recentOrderData.midtrans_info"
                    :href="recentOrderData.midtrans_info.redirect_url"
                    class="btn btn-wz-proceed mt-2 mt-lg-0 ml-lg-2"
                  >
                    Pay Now
                  </a>
                  <a
                    v-else-if="
                      Object.keys(recentOrderData).filter(
                        (el) =>
                          /(?![voice_message])\w+_url/i.test(el) &&
                          recentOrderData[el]
                      ).length
                    "
                    :href="
                      recentOrderData.atome_url ||
                      recentOrderData.indodana_url ||
                      recentOrderData.kredivo_url
                    "
                    class="btn btn-wz-proceed mt-2 mt-lg-0 ml-lg-2"
                  >
                    Pay Now
                  </a>
                </template>
              </template>
              <!-- Button receive order for shipping status -->
              <template
                v-else-if="
                  recentOrderData.sellers[0].order_status.shipped &&
                  !recentOrderData.sellers[0].order_status.completed
                "
              >
                <button
                  class="btn btn-wz-proceed mt-2 mt-lg-0 ml-lg-2"
                  v-b-modal="'modal-order-received'"
                  @click="selectedOrder = recentOrderData.sellers[0]"
                >
                  Order Received
                </button>
              </template>
              <!-- Delivered Order -->
              <template
                v-else-if="recentOrderData.sellers[0].order_status.completed && recentOrderData.sellers[0].items.find(el => !el.is_reviewed)"
              >
                <button
                  v-b-modal="'modal-review'"
                  @click="sendDataToModal(recentOrderData.sellers[0].items)"
                  class="btn btn-wz-proceed mt-2 mt-lg-0 ml-lg-2"
                >
                  Review Order
                </button>
              </template>
            </div>
          </div>
          <div class="row border-bottom-p12" v-if="recentOrderData.id_encrypt && recentOrderData.email_voice">
            <div class="col-lg-6 col-12">
              <div class="mb-2">E-Giftcard</div>
              <b>To: {{ recentOrderData?.name_voice || recentOrderData.email_voice }}</b>
            </div>
            <div class="col-lg-6 col-12 mt-lg-0 mt-2 text-lg-right">
              <router-link
                :to="`/campaign/voice/${recentOrderData.id_encrypt}`"
                class="btn btn-wz-clear btn-w-100"
              >
                <b-img :src="require('@/assets/img/gift-icon.svg')" alt="" class="mr-1" width="16" />
                <span>View</span>
              </router-link>
            </div>
          </div>
          <!-- Seller(s) -->
          <template v-for="(seller, indexSeller) in recentOrderData.sellers">
            <div
              class="row border-bottom-p12"
              :class="{ 'mt-2' : indexSeller > 0 }"
              :key="`seller-${seller.id}`"
            >
              <div class="col-lg col-6">
                <p class="mb-1">Merchant</p>
                <p class="mt-1 bold mb-0">{{ seller.seller_name }}</p>
              </div>
              <div
                class="col-lg col-6"
                v-if="recentOrderData.reseller_id && seller.reseller_commission"
              >
                <p class="mb-1">Comission</p>
                <p class="mt-1 text-gold bold mb-0">
                  {{ `${$helpers.format.price(seller.reseller_commission)}` }}
                </p>
              </div>
              <div class="col-lg-8 col-12 text-right p8 mt-2 mt-lg-0">
                <template v-if="/OV|RC/.test(recentOrderData.status)">
                  <!-- //* Takeout invoice for reseller -->
                  <!-- Invoice for business partner -->
                  <!-- <button
                    class="btn btn-wz-clear"
                    @click="selectedOrderNumber = seller.order_number; selectedMerchantId = seller.id"
                    v-b-modal="'modal-print-invoice'"
                    v-if="
                      $store.getters.getResellerData &&
                      recentOrderData.reseller_id
                    "
                  >
                    <fawesome-pro variant="far" icon="arrow-to-bottom" class="mr-2" size="lg" v-if="recentOrderData.voice_message_url" /> INVOICE
                  </button> -->
                  <!-- Invoice for customer -->
                  <button
                    class="btn btn-wz-clear btn-w-48 ml-lg-3"
                    @click="getDefaultInvoice(seller.id)"
                  >
                    <fawesome-pro variant="far" icon="arrow-to-bottom" class="mr-2" size="lg" /> Invoice
                  </button>
                  <button
                    class="btn btn-wz-clear mt-2 mt-lg-0 ml-lg-2"
                    @click="chatOrder"
                  >
                    ASK MERCHANT
                  </button>
                </template>
              </div>
            </div>
            <!-- Product(s) by seller-->
            <div
              class="row p-12-0"
              :class="{ 'border-bottom-p12' : indexSeller < recentOrderData.sellers.length - 1 }"
              :key="`product-by-seller-${seller.id}`"
            >
              <template v-for="(item, indexItem) in seller.items">
                <div class="col-lg-9 col-12" :class="{'mt-2' : indexItem > 0}" :key="`product-${indexItem}`">
                  <div class="cart-item ml-0">
                    <!-- <div class="product-thumb">
                      <img
                        :src="item.image_url"
                        @error="replaceByDefaultImage"
                      />
                    </div> -->
                    <!-- <div class="product-thumb"> -->
                      <router-link class="product-thumb" :to="`/product/${item.slug}`">
                        <img
                          :src="item.image_url"
                          :alt="setProductImgAltText(item)"
                          @error="replaceByDefaultImage"
                        />
                      </router-link>
                    <!-- </div> -->
                    <div class="ml-30 mt-2">
                      <!-- <div class="product-brand-big">
                        {{ item.product_name }}
                      </div> -->
                      <router-link class="product-brand-big text-black" :to="`/product/${item.slug}`">
                        {{ item.product_name }}
                      </router-link>
                      <div class="price">
                        {{ `${$helpers.format.price(item.price_display)}` }}
                      </div>
                      <div class="d-lg-flex">
                        <div class="payment-detail">
                          Color : <span class="no-bold">{{ item.color }}</span>
                        </div>
                        <div class="payment-detail ml-lg-3">
                          Size : <span class="no-bold">{{ item.size }}</span>
                        </div>
                        <div class="payment-detail ml-lg-3">
                          QTY : <span class="no-bold">{{ item.quantity }}</span>
                        </div>
                      </div>
                      <div class="payment-detail" v-if="item.notes">
                        Notes : <span class="no-bold">{{ item.notes }}</span>
                      </div>
                      <!-- Product Additional Info -->
                      <div class="product-status-badge preorder-badge" v-if="item.status_stock == false">Pre-order</div>
                      <div class="preorder-estimated d-flex flex-lg-row flex-column" v-if="item.estimated_time_arrival_millis">
                        <div>Estimated Delivery:</div>
                        <b class="ml-lg-1">{{ formatEpochMsToLocalTime(item.estimated_time_arrival_millis) }}</b> 
                      </div>
                      <!-- TODO take out <br> if product img not using position absolute / height fit to parent -->
                      <br v-if="!item.notes && !item.estimated_time_arrival_millis">
                    </div>
                  </div>
                </div>
                <!-- Button buy again for cancelled/expired/delivered status -->
                <div class="col-lg-3 col-12 text-right" :class="{'mt-2' : indexItem > 0}" :key="`buy-again-${indexItem}`">
                  <template v-if="/CA|EX|RC/.test(recentOrderData.status)">
                    <router-link
                      :to="`/product/${item.slug}`"
                      class="btn btn-wz-proceed mt-2 mt-lg-0"
                    >
                      Buy Again
                    </router-link>
                  </template>
                </div>
                <div class="col-12 order-additional-info" :key="`additional-info-${indexItem}`">
                  <Accordion class="mt-2" v-if="item.bundled_product" title="Bundle Product Detail">
                    <ProductRow :products="JSON.parse(item.bundled_product)"/>
                  </Accordion>
                </div>
              </template>
              <div class="col-12 order-additional-info">
                <Accordion class="mt-2" title="Shipping Information">
                  <div class="shipping-address text-black mt-1" v-if="seller.shipment_type == 1">
                    <div class="title">Pick-up Point</div>
                    <div class="address-name mt-1">{{ seller.pick_up_point_name }}</div>
                    <div class="mt-1">{{ seller.pick_up_point_address }}</div>
                    <div>{{ `${seller.pick_up_point_duration} day(s) of pick-up time` }}</div>
                    <div>
                      {{ seller.pick_up_point_operating_day }}
                      ({{
                        seller
                          .pick_up_point_operating_start_time
                          ? seller.pick_up_point_operating_start_time.substring(
                              0,
                              5
                            )
                          : "09:00"
                      }}
                      WIB -
                      {{
                        seller
                          .pick_up_point_operating_end_time
                          ? seller.pick_up_point_operating_end_time.substring(
                              0,
                              5
                            )
                          : "17:00"
                      }}
                      WIB)
                    </div>
                    <iframe
                      class="pickup-point-iframe mt-1"
                      frameborder="0"
                      :src="
                        'https://www.google.com/maps/embed/v1/place?key=AIzaSyD4OaFeki2cLk8pqqgnoNum-iHRe7OzWKE&amp;q=' +
                        encodeURIComponent(
                          seller.pick_up_point_name +
                            ' (' +
                            seller.pick_up_point_address +
                            ')'
                        ) +
                        '&amp;center=' +
                        seller.pick_up_point_latitude +
                        ',' +
                        seller.pick_up_point_longitude +
                        '&amp;zoom=18'
                      "
                      allowfullscreen
                    >
                    </iframe>
                  </div>
                  <div class="shipping-name text-black mt-1" v-else-if="seller.shipment_type == 0">
                    <img class="align-top" src="@/assets/img/ic-delivery.svg" alt="" width="24" />
                    {{ `${seller.courier_name} - ${seller.courier_service_name}` }}
                    <div>Shipping Fee : <span class="text-gold">{{ `${$helpers.format.price(seller.shipping)}` }}</span></div>
                  </div>
                </Accordion>
              </div>
            </div>
          </template>
          <div class="row">
            <div class="col-md-6 col-12 p-24">
              <!-- Billing Address -->
              <div class="shipping-address">
                <div class="title">{{ setShippingAddressTitle(recentOrderData.sellers) }}</div>
                <div class="mt-1">{{ recentOrderData.billing_address.name }}</div>
                <div>{{ recentOrderData.billing_address.phone }}</div>
                <div>{{ recentOrderData.billing_address.address }}</div>
                <div>{{ recentOrderData.billing_address.city }}</div>
                <div>{{ recentOrderData.billing_address.districts }}</div>
                <div>{{ recentOrderData.billing_address.postal_code }}</div>
              </div>
            </div>
            <div class="col-md-6 col-12 p-24">
              <table class="order-detail">
                <tbody>
                  <!-- Subtotal -->
                  <tr>
                    <td>Subtotal</td>
                    <td class="text-right">
                      {{
                        perMerchant
                          ? $helpers.format.price(recentOrderData.sellers[0].sub_total)
                          : $helpers.format.price(recentOrderData.sub_total)
                      }}
                    </td>
                  </tr>
                  <!-- Handling Fee -->
                  <tr>
                    <td>Handling Fee</td>
                    <td class="text-right">
                      {{
                        perMerchant
                          ? $helpers.format.price(recentOrderData.sellers[0].handling_fee)
                          : $helpers.format.price(recentOrderData.handling_fee)
                      }}
                    </td>
                  </tr>
                  <!-- Shipping -->
                  <tr>
                    <td>Shipping Fee</td>
                    <td class="text-right">
                      {{
                        perMerchant
                          ? $helpers.format.price(recentOrderData.sellers[0].shipping)
                          : $helpers.format.price(recentOrderData.shipping)
                      }}
                    </td>
                  </tr>
                  <!-- Voucher Shipping -->
                  <tr v-if="voucherShipping > 0">
                    <td>Voucher Shipping</td>
                    <td class="text-right">
                      - {{ $helpers.format.price(voucherShipping) }}
                    </td>
                  </tr>
                  <!-- Voucher Promo -->
                  <tr v-if="voucherPromo > 0">
                    <td>Voucher Promo</td>
                    <td class="text-right">
                      - {{ $helpers.format.price(voucherPromo) }}
                    </td>
                  </tr>
                  <!-- Voucher Bus Partner -->
                  <tr v-if="voucherBusPartner > 0">
                    <td>Voucher Bus Partner</td>
                    <td class="text-right">
                      - {{ $helpers.format.price(voucherBusPartner) }}
                    </td>
                  </tr>
                  <!-- Discount -->
                  <tr>
                    <td>Discount</td>
                    <td class="text-right">
                      - {{
                        perMerchant
                          ? $helpers.format.price(recentOrderData.sellers[0].discount)
                          : $helpers.format.price(recentOrderData.discount)
                      }}
                    </td>
                  </tr>
                  <!-- Liz-point -->
                  <tr>
                    <td>Liz-Point</td>
                    <td class="text-right">
                      - {{ $helpers.format.price(perMerchant ? recentOrderData.sellers[0].seller_loyalty : recentOrderData.loyalty_point, null, 0, true) }}
                    </td>
                  </tr>
                  <!-- Payment Method -->
                  <tr>
                    <td>Payment Method</td>
                    <td class="text-right">
                      {{ recentOrderData.payment_method }}
                    </td>
                  </tr>
                  <!-- Total -->
                  <tr class="bold">
                    <td>Total</td>
                    <td class="text-right">
                      {{
                        perMerchant
                          ? $helpers.format.price(recentOrderData.sellers[0].total)
                          : $helpers.format.price(recentOrderData.total)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
        <section v-else-if="!isNaN(error)" class="product-list-heading text-center my-4">
          <img src="@/assets/img/page-not-found.png" alt="Product not found" />
          <p class="mt-4 mb-0"><b>Oops, order not found</b></p>
          <p>
            Please check your url.
            Going back in {{error}} second{{ error > 1 ? 's' : '' }}<br/>
            Not working? <router-link to="/recent-order">Click here</router-link>
          </p>
        </section>
      </div>
    </section>
    <ModalOrderCancel
      v-if="selectedOrder"
      :items="selectedOrder"
      v-on:cancelOrderResponse="$router.push('/recent-order')"
    />
    <ModalOrderReceive
      v-if="selectedOrder"
      :items="selectedOrder"
      v-on:receiveOrderResponse="$router.push('/recent-order?status=delivered')"
    />
    <!-- <ModalPrintInvoice v-if="selectedOrderNumber" :order_number="selectedOrderNumber" :merchant_id="selectedMerchantId" /> -->
    <ModalTrackOrder v-if="selectedOrder" :items="selectedOrder" />
    <ModalReview v-if="review" :items="review" />
  </div>
</template>

<script>
import { toaster, formatter } from "@/_helpers";
import { qbHelper } from "@/_services";
import ModalReview from "@/components/modals/ModalReview";
import ModalOrderCancel from "@/components/modals/ModalOrderCancel";
import ModalOrderReceive from "@/components/modals/ModalOrderReceive";
// import ModalPrintInvoice from "@/components/modals/ModalPrintInvoice";
import ModalTrackOrder from "@/components/modals/ModalTrackOrder";
import FloatWhatsapp from "@/components/mains/FloatWhatsapp";
const Accordion = () => import('@/components/bundling/Accordion');
const ProductRow = () => import('@/components/bundling/ProductRow');
import Chat from "@/components/mains/Chat";
import moment from "moment";
// import axios from "axios";

export default {
  name: "OrderDetail",
  components: {
    ModalReview,
    ModalOrderCancel,
    ModalOrderReceive,
    // ModalPrintInvoice,
    ModalTrackOrder,
    FloatWhatsapp,
    Accordion,
    ProductRow,
    Chat,
  },
  data() {
    return {
      recentOrderData: null,
      perMerchant: false,
      seller: null,
      selectedOrder: undefined,
      // selectedOrderNumber: undefined,
      selectedMerchantId: undefined,
      review: undefined,
      back: true,
      error: NaN,

      // QB
      qbRegister: undefined,

      // PO Status
      preorder_status : true,
    };
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
    orderDetail() {
      return this.$store.getters.getOrderDetail;
    },
    voucherShipping() {
      return this.recentOrderData.sellers
        .map((el) =>
          el.voucher_used.find((rel) => rel.voucher_name.includes("<Shipping>"))
        )
        .reduce((acc, obj) => {
          return acc + (obj ? obj.value : 0);
        }, 0);
    },
    voucherPromo() {
      return this.recentOrderData.sellers
        .map((el) =>
          el.voucher_used.find((rel) => !rel.voucher_name.includes("<"))
        )
        .reduce((acc, obj) => {
          return acc + (obj ? obj.value : 0);
        }, 0);
    },
    voucherBusPartner() {
      return this.recentOrderData.sellers
        .map((el) =>
          el.voucher_used.find((rel) => rel.voucher_name.includes("<Reseller>"))
        )
        .reduce((acc, obj) => {
          return acc + (obj ? obj.value : 0);
        }, 0);
    },
  },
  mounted() {
    this.$nextTick(function() {
      // wait for store state update
      const order = this.orderDetail;
      const url = this.$route.params.id;
      if (url.includes("-")) {
        this.perMerchant = true;
      }
      if (order) {
        if (this.perMerchant) {
          if (url.includes(order.order_number)) {
            this.recentOrderData = this.perMerchantFormat([order], url);
          } else {
            this.getOrderDetail(url);
          }
        } else {
          if (order.order_number != url) {
            this.getOrderDetail(url);
          } else {
            this.recentOrderData = order;
          }
        }
      } else {
        this.getOrderDetail(url);
      }
    });
    this.back = window.history.length > 1;
  },
  methods: {
    getOrderStatusText(order) {
      if (/CH|WC|CA|EX/.test(order.status)) return order.status_text;

      const order_status = order.sellers[0].order_status;
      if (order_status.completed) return order_status.completed_detail.last_detail_status || 'Receive By Customer';
      else if (order_status.shipped) return order_status.shipped_detail.last_detail_status || 'On Delivery';
      else if (order_status.processing) return order_status.processing_detail.last_detail_status || 'Packing';
    },
    getOrderStatusColor(order) {
      if (/CH|WC/.test(order.status)) return '#DB7C3B'; //* Unfinished Order
      if (/CA|EX/.test(order.status)) return '#D32F2F'; //* Cancelled / Expired Order
      return '#4CAF50'; //* Packing / Shipping / Delivered Order
    },
    async getOrderDetail(orderNumber) {
      try {
        //TODO need clean code when set order detail data from api response
        const res = await (orderNumber.includes("-") ? this.$api.order.getOrderDetail(orderNumber) : this.$api.order.getOrderDetailGeneral(orderNumber));

        if (res.status === 200) {
          const data = orderNumber.includes("-")
            ? this.perMerchantFormat([res.data.data], orderNumber)
            : [res.data.data].find((el) => el.order_number == orderNumber);

          if (new RegExp(["Waiting", "Unpaid", "Cancel"].join("|")).test(data.payment_status) && !orderNumber.includes("-") || (data.payment_status == "Paid" && orderNumber.includes("-")))
            this.recentOrderData = data;
          else
            this.$router.push("/recent-order");
        } else {
          this.error = 5;
        }
      } catch (e) {
        console.error(e);
      }
      // axios
      //   .get(this.apiUrl + "users/order/lists?offset=0&limit=0", {
      //     headers: {
      //       api_key: this.apiKey,
      //       token: this.$store.getters.getUserToken,
      //       "Content-Type": "application/json",
      //     },
      //   })
      //   .then((res) => {
      //     let data = {};
      //     if (orderNumber.includes("-")) {
      //       data = this.perMerchantFormat(res.data.data, orderNumber);
      //     } else {
      //       data = res.data.data.find((el) => el.order_number == orderNumber);
      //     }
      //     if (
      //       (new RegExp(["Waiting", "Unpaid", "Cancel"].join("|")).test(
      //         data.payment_status
      //       ) &&
      //         !orderNumber.includes("-")) ||
      //       (data.payment_status == "Paid" && orderNumber.includes("-"))
      //     ) {
      //       this.recentOrderData = data;
      //     } else {
      //       this.$router.push("/recent-order");
      //     }
      //   })
      //   .catch((error) => console.log(error));
    },
    perMerchantFormat(data, url) {
      return data.filter((el) => {
        const seller = el.sellers.find((rel) => rel.order_number == url);
        if (seller) {
          el.sellers = [seller];
          return el;
        }
      })[0];
    },
    sendDataToModal(items) {
      this.review = items;
      for (let i = 0; i < this.review.length; i++) {
        if (this.review[i].is_reviewed == false) {
          this.review[i].review = [
            {
              rating: 0,
              comment: "",
              tag: "",
              path_image: [],
              file_image: [],
              anonymous: false,
            },
          ];
        }
      }
    },
    chatOrder() {
      const token = this.$store.getters.getUserToken;
      if (token) {
        const chat_window = document.getElementById("float-chat-window");
        const chat_button = document.getElementById("float-whatsapp");

        if (!chat_window.classList.contains("active")) {
          chat_window.classList.toggle("active");
          chat_button.classList.toggle("active");
        }
        // If user don't have quickblox account
        if (!this.$store.getters.getUserData.quickblox_id) this.qbRegister = true;
        // If user already have quickblox account
        else this.createDialogOrder();
      }
    },
    createDialogOrder() {
      this.$store.dispatch({
        type: "setIsLoading",
        key: "dialogList",
        value: true,
      });
      qbHelper.createDialog(
        this.recentOrderData.sellers[0].merchant_id, // merchant id
        this.recentOrderData.sellers[0].id, // sales order id
        "order" // type for prepare chat member merchant
      );
    },
    async copy(value, type) {
      await navigator.clipboard.writeText(value);
      toaster.make(
        type == "tracking_number"
          ? "Tracking number has been copied to your clipboard"
          : "Pickup code has been copied to your clipboard",
        "success"
      );
    },
    async getDefaultInvoice(id) {
      try {
        const res = await this.$api.order.getDefaultInvoice(id);
        if (res.status === 200) window.open(res.data.data);
      } catch (e) {
        console.error(e);
      }
    },
    setShippingAddressTitle(sellers) {
      if (!sellers) return
      const courierFound = sellers.find((el) => el.shipment_type == 0)
      if(courierFound) return "Billing & Shipping Address"
      else return "Billing Address"
    },
    formatDate(value) {
      return moment(String(value)).format("D MMMM YYYY");
    },
    formatEpochMsToLocalTime(value) {
      return formatter.epochMsToLocalTime(value, "DD MMMM YYYY")
    },
    replaceByDefaultImage(img) {
      img.target.src = require("@/assets/img/product-default.png");
    },
    setProductImgAltText(product) {
      return `${product.brand_name} ${product.product_name}${!product.color.includes('--none--') && product.size > 0 ? ' with' : ''}${!product.color.includes("--none--") ? ` ${product.color} color` : ''}${product.size > 0 ? ` size ${product.size}` : ''}`
    },
  },
  watch: {
    error(val) {
      if (isNaN(val)) return;
      else if (val > 0)
        setTimeout(() => this.error -= 1, 1000);
      else this.$router.push("/recent-order");
    }
  }
};
</script>

<style scoped>
/* fill width 100% for mobile view */
@media only screen and (max-width: 992px) {
  .btn {
    width: 100%;
  }
}
/* fixed button size for desktop view */
@media only screen and (min-width: 992px) {
  .btn-wz-proceed,
  .btn-wz-clear {
    width: 180px;
    font-size: 13px;
  }
}
/* custom btn-wz-clear (same as btn-wz-proceed) */
.btn-wz-clear {
  letter-spacing: normal;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>